import React from "react"
import { graphql } from "gatsby"

import {
  Seo,
  Layout,
  HeaderTextOnly,
  RichTextTickBullets,
  FormJoinMailingList,
  BlogArticles,
  SectionTextWithImage,
  Accordions,
} from "../components"
import {
  BlogArticleParentType,
  BlogArticleTopicType,
  GatsbyLocationObject,
} from "../types"
import { handleSectionBackground } from "../utils"

type BlogPage = {
  location: GatsbyLocationObject
  data: {
    contentfulPageGeneric: {
      id: string
      heading: string
      header: {
        raw: string
      }
      pageSections: any
      seo: {
        description: {
          description: string
        }
        titleTemplate: {
          extension: string
        }
        title: string
        image: {
          fixed: {
            src: string
          }
        }
      }
    }
    allContentfulPageBlogArticle: {
      edges: Array<BlogArticleParentType>
    }
    allContentfulBlogArticleTopic: {
      edges: Array<{ node: BlogArticleTopicType }>
    }
  }
}

const Blog = ({ data, location }: BlogPage) => {
  const page = data?.contentfulPageGeneric
  const blogArticles = data?.allContentfulPageBlogArticle.edges
  const topics = data?.allContentfulBlogArticleTopic.edges

  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderTextOnly alignment="left" heading={page?.heading}>
        <RichTextTickBullets content={page?.header} />
        <FormJoinMailingList variant="blog" />
      </HeaderTextOnly>
      <BlogArticles
        articles={blogArticles}
        topics={topics}
        location={location}
      />
      {page?.pageSections?.map((section: any, index: number) => {
        if (section.__typename === "ContentfulPageSectionTextWithImage") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              contentful_id={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "grey-lightest" : "white"
              )}
              heading={section.heading}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
            />
          )
        } else if (section.__typename === "ContentfulPageSectionAccordions") {
          return (
            <SectionTextWithImage
              key={section.contentful_id}
              contentful_id={section.contentful_id}
              backgroundColour={handleSectionBackground(
                section.backgroundColour,
                index % 2 === 0 ? "grey-lightest" : "white"
              )}
              heading={section.heading}
              content={section.content}
              image={section?.media?.media?.gatsbyImageData}
              imageAlt={section?.media?.media?.title}
              showImageFirst={section.showImageFirst}
              imageBlobBackground={section.imageBlobBackground}
              showSignUpButton={section.showSignUpButton}
              scheduleDemoButton={section.scheduleDemoButton}
              file={section?.media?.media?.file?.url}
              youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
              variant="accordions"
            >
              <Accordions accordions={section.accordions} />
            </SectionTextWithImage>
          )
        }
      })}
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query getBlogPageData {
    contentfulPageGeneric(name: { eq: "Blog" }) {
      id
      heading
      header {
        raw
      }
      pageSections {
        ... on ContentfulPageSectionAccordions {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          accordions {
            heading
            body {
              raw
            }
            contentful_id
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
        ... on ContentfulPageSectionTextWithImage {
          __typename
          contentful_id
          heading
          content {
            raw
          }
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          showImageFirst
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
        }
      }
      seo {
        description {
          description
        }
        titleTemplate {
          extension
        }
        title
        image {
          fixed {
            src
          }
        }
      }
    }
    allContentfulPageBlogArticle(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          contentful_id
          slug
          topic {
            contentful_id
            name
            slug
          }
          author {
            name
            contentful_id
          }
          featuredImage {
            title
            gatsbyImageData
          }
          createdAt
          heading
        }
      }
    }
    allContentfulBlogArticleTopic(sort: { order: ASC, fields: name }) {
      edges {
        node {
          contentful_id
          name
          slug
        }
      }
    }
  }
`
